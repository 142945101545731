import React, {useContext, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {PricingDataContext} from "../store/PricingDataProvider";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import {
    useHistory
} from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://fivetn.com">
                Fivetn
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const context = useContext(PricingDataContext);
    const {isAuth, setIsAuth} = context;
    let history = useHistory();
    useEffect(()=>{
        if (isAuth){
            history.push('/')
        }
    },[isAuth])

    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            name: '',
            password: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Username is required.'),
            password: Yup.string().required('Password is required')
        }),
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            try {
                let response = await axios.post(`${process.env.REACT_APP_URL}/api/login`, values)
                localStorage.setItem('token','my-token')
                setIsAuth(true)
                history.push('/');
                NotificationManager.success('Login successfully');
            } catch (error) {
                console.log('Error:', error);
                NotificationManager.error('Username or password incorrect!')
            } finally {
                setSubmitting(false)
            }
        }
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        onChange={formik.handleChange}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="name"
                        autoComplete="name"
                        autoFocus
                    />
                    {formik.touched['name'] && formik.errors['name'] ? (
                        <div className="Error">{formik.errors['name']}</div>
                    ) : null}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={formik.handleChange}
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {formik.touched['password'] && formik.errors['password'] ? (
                        <div className="Error">{formik.errors['password']}</div>
                    ) : null}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={formik.handleSubmit}
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={2}>
                <Copyright/>
            </Box>
        </Container>
    );
}