import React, {useEffect, useRef} from 'react';
import {createPortal} from "react-dom";

const Portal = ({children}) => {

    let modalRoot = document.getElementById('modal');
    if (!modalRoot) {
        modalRoot = document.createElement('div');
        modalRoot.setAttribute('id', 'modal');
        document.body.appendChild(modalRoot)
    }
    let modalElement = useRef(document.createElement('div'));

    useEffect(() => {
        let currentModalEl = modalElement.current;
        modalRoot.appendChild(modalElement.current);
        return () => modalRoot.removeChild(currentModalEl);
    }, [modalElement]);

    return createPortal(children, modalElement.current)
};
export default Portal;