import './App.css';
import React, {useContext, useEffect} from "react";
import ContractsSummary from "./containers/ContractsSummary";
import Overhead from "./containers/Overhead";
import Metrics from "./containers/Metrics";
import NewContract from "./containers/NewContract";
import {PricingDataContext, PricingDataProvider} from "./store/PricingDataProvider";
import SignIn from "./containers/SignIn";
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";
import 'react-notifications/lib/notifications.css';

import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import {NotificationContainer} from "react-notifications";

const App = (props) => {
    const context = useContext(PricingDataContext);
    const {isAuth, setIsAuth} = context;
    let history = useHistory();
    console.log(context)
    useEffect(async () => {
        console.log('each time')
        const token = await localStorage.getItem('token')
        if (token) {
            setIsAuth(true)
        } else {
            setIsAuth(false)
        }
    }, [isAuth])

    useEffect(() => {
        if (!isAuth) {
            history?.push('/login')
        } else {
            history?.push('/')
        }
    }, [setIsAuth])

    const logout = () => {
        localStorage.removeItem('token')
        setIsAuth(false)
    }
    return (
        <div className="App">
            <BrowserRouter>
                {isAuth && <Box mt={3}  textAlign={'center'}>
                    <Button variant="contained" size={"large"}
                            onClick={logout}
                            color={"secondary"}>
                        LOGOUT
                    </Button>
                </Box>}
                <Switch>
                    <Route path={'/login'}>
                        <SignIn/>
                    </Route>
                </Switch>
                <Switch>
                    <Route exact path={'/'}>
                        <>
                            <div className="Dynamic-Table">
                                <ContractsSummary/>
                                <Overhead/>
                            </div>
                            <div className="Static-Table">
                                <NewContract/>
                                <Metrics/>
                            </div>
                        </>
                    </Route>
                </Switch>
            </BrowserRouter>
            <NotificationContainer/>

        </div>
    );
}

export default App;
