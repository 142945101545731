export function formatNumber(num, precision, separator) {
    let parts;
    // Judging whether it is a number
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        // Convert data like. 5, 5. into 0. 5, 5, which is accurate for data processing, and why
        // Do not write if (! IsNaN (num = parseFloat (num)) & isFinite (num) directly in judgment
        // Because parseFloat has a strange accuracy problem, such as parseFloat (12312312.1234567119)
        // The value becomes 12312312312.123456713
        num = Number(num);
        // Processing decimal digits
        num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
        // Separating decimal and integer parts of numbers
        parts = num.split('.');
        // The integer part is separated by [separator], borrowing a famous regular expression
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));

        return parts.join('.');
    }
    return NaN;
}