import {createContext, useState, useEffect} from 'react';

export const PricingDataContext = createContext({});

export const PricingDataProvider = ({children}) => {

    //login
    const [isAuth, setIsAuth] = useState(false);

    //Contract summary
    const [fte, setFte] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [employees, setEmployees] = useState(0);
    const [productCost, setProductCost] = useState(0);
    const [otherCost, setOtherCosts] = useState(0);
    const [totalGrossMargin, setTotalGrossMargin] = useState(0);
    const [totalGrossMarginPercentage, setTotalGrossMarginPercentage] = useState(0);

    //Overheads
    const [ammount, setAmmount] = useState(0);
    const [cash, setCash] = useState(0);


    //New contract
    const [fteNC, setFteNC] = useState(0);
    const [costPerFteNC, setCostPerFteNC] = useState(0);
    const [salaryNC, setSalaryNC] = useState(0);
    const [productCostNC, setProductCostNC] = useState(0);
    const [otherCostNC, setOtherCostNC] = useState(0);
    const [totalEstimatedCostNC, setTotalEstimatedCostNC] = useState(0);
    const [targetedMarginNC, setTargetedMarginNC] = useState(0);
    const [revenueNC, setRevenueNC] = useState(0);
    const [grossMarginNC, setGrossMarginNC] = useState(0);
    const [additionalOverheadNC, setAdditionalOverheadNC] = useState(0);


    const value = {
        fte,
        setFte,
        revenue,
        setRevenue,
        employees,
        setEmployees,
        productCost,
        setProductCost,
        otherCost,
        totalGrossMargin,
        setOtherCosts,
        setTotalGrossMargin,
        totalGrossMarginPercentage,
        setTotalGrossMarginPercentage,
        ammount,
        setAmmount,
        cash,
        setCash,
        fteNC,
        setFteNC,
        costPerFteNC,
        setCostPerFteNC,
        salaryNC,
        setSalaryNC,
        productCostNC,
        setProductCostNC,
        otherCostNC,
        setOtherCostNC,
        totalEstimatedCostNC,
        setTotalEstimatedCostNC,
        targetedMarginNC,
        setTargetedMarginNC,
        revenueNC,
        setRevenueNC,
        grossMarginNC,
        setGrossMarginNC,
        additionalOverheadNC,
        setAdditionalOverheadNC,
        isAuth,
        setIsAuth
    }
    return (
        <PricingDataContext.Provider value={value}>
            {children}
        </PricingDataContext.Provider>
    )
}