import React from 'react';
import './Modal.css';
import Portal from "./Portal";

const Modal = (props) => {
    const {children, title, actions, form} = props;
    const {data, open, close, isOpen} = props.modal;
    const Wrapper = form ? 'form' : 'div';
    return (
        <Portal className="Modal-Wrapper">
            {
                isOpen &&
                <>
                    <div className="Backdrop" onClick={close}></div>
                    <Wrapper className="Modal">
                        <h2>{title}</h2>
                        <div className={'Children'}>
                            {children}
                        </div>
                        <div className={"Actions"}>
                            {actions}
                        </div>
                    </Wrapper>
                </>
            }
        </Portal>
    )
}
export default Modal;