import React from 'react';
import './Input.css';

const Input = ({value, type, name, label, required, formik, readOnly}) => {
    return (
        <div className="Input">
            <label>{label}</label>
            <input value={value} type={type} readOnly={readOnly ? readOnly : false} {...formik.getFieldProps(name)}/>
            {required && formik.touched[name] && formik.errors[name] ? (
                <div className="Error">{formik.errors[name]}</div>
            ) : null}
        </div>
    )
};
export default Input;