import React, {useState, useEffect, useContext, useRef, useCallback} from "react";
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import {useModal} from "../hooks/useModal";
import Input from "../components/Input";
import Modal from "../components/Modal";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "../components/Select";
import './Overhead.css';
import {PricingDataContext} from "../store/PricingDataProvider";
import axios from 'axios';
import {Paper, TablePagination} from "@material-ui/core";
import {TablePaginationActions} from '../components/Pagination';
import {NotificationManager} from "react-notifications";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        maxWidth: 800,
        overflowY: 'auto',
    },
});

const overheadsType = {
    CATEGORY: 'category',
    NAME: 'name',
    AMMOUNT: 'amount',
    CASH: 'amount_ron',
}

const Category = [
    {key: 'car', value: 'Car'},
    {key: 'consulant', value: 'Consultant'},
    {key: 'debt', value: 'Debt'},
    {key: 'fuel', value: 'Fuel'},
    {key: 'internet', value: 'Internet'},
    {key: 'phone', value: 'Phone'},
    {key: 'rent', value: 'Rent'},
    {key: 'salary', value: 'Salary'},
    {key: 'stapel', value: 'Stapel'},
    {key: 'utilities', value: 'Utilities'},
    {key: 'other', value: 'Other'},
];

const Overhead = () => {

    const [rowsState, setRowState] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    //pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalOverheads, setTotalOverheads] = useState(0)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/api/overheads`)
            .then(res => {
                setRowState(res.data);
                setTotalOverheads(res.data.length)
                refState.current = res.data
            }).catch(err => {

            console.log('ERR GET', err)
        })
    }, [])
    useEffect(() => {
        setAmmount(totalType(overheadsType.AMMOUNT));
        setCash(totalType(overheadsType.CASH));
    }, [rowsState, setRowState])


    //update table

    const context = useContext(PricingDataContext);
    const {ammount, setAmmount, cash, setCash} = context;

    const classes = useStyles();
    const modal = useModal();
    const deleteModal = useModal();

    const editRow = useCallback((id, value, type) => {
        refState.current = rowsState;
        let rowCopy = [...rowsState];
        let elementFind = rowCopy.filter(el => el.id === id);
        elementFind[0][type] = (type === 'name' || type === 'category') ? value : parseFloat(value);
        setRowState(rowCopy);
    }, [rowsState]);

    const refState = useRef(null);

    useEffect(() => {
        let timer = null
        if (updateStatus && refState.current?.length === rowsState.length) {
            timer = setTimeout(() => {
                try {
                    axios.put(`${process.env.REACT_APP_URL}/api/overheads`, rowsState).then(res => {
                        console.log('Success')
                        NotificationManager.success('Overheads table successfully updated!');
                    }).catch(err => {
                        console.log(err)
                    })
                } catch (ex) {
                    console.log("OVERHEADS:", ex)
                }
            }, 3000)
        }
        return () => {
            clearTimeout(timer)
            setUpdateStatus(true)
        }
    }, [rowsState])


    const totalType = (type) => {
        let total = rowsState.map((item) => {
            return item[type]
        }).reduce((total, current) => parseInt(total) + parseInt(current), 0);
        return total;
    };
    const formik = useFormik({
        initialValues: {
            category: '',
            name: '',
            amount: '',
            // amount_ron: '',
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Category field is required.'),
            name: Yup.string().required('Name field is required.'),
            amount: Yup.number().required('Amount field is required.'),
            // amount_ron: Yup.number().required('Amount[RON] field is required.'),
        }),
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            try {
                axios.post(`${process.env.REACT_APP_URL}/api/overheads`, values)
                    .then(res => {
                        setPage(0)
                        axios.get(`${process.env.REACT_APP_URL}/api/overheads`).then(res => {
                            setRowState(res.data);
                            setTotalOverheads(res.data.length)
                            refState.current = res.data
                        }).catch(err => {
                            console.log('ERR GET')
                        })
                        NotificationManager.success('Overhead successfully created!');
                        setUpdateStatus(false)
                        formik.resetForm();
                        modal.close();
                    }).catch(err => {
                    console.log(err)
                })
            } catch (error) {
                console.log('Error:', error);
                alert(error)
            } finally {
                setSubmitting(false)
            }
        }
    });
    const deleteFormik = useFormik({
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            try {
                console.log('Contract deleted!');

                deleteModal.close();
            } catch (error) {
                console.log('Error:', error);
                alert(error)
            } finally {
                setSubmitting(false)
            }
        }
    });

    const deleteContract = (id) => {
        deleteModal.open(id);
        let contractExist = rowsState.find(it => it.id === id)
        setDeletedContract(contractExist.client)
    }

    async function handleDeleteContract() {
        try {
            axios.delete(`${process.env.REACT_APP_URL}/api/overheads/${deleteModal.data}`)
                .then(res => {
                    let state = [...rowsState];
                    let newState = state.filter(item => item.id !== deleteModal.data)
                    setRowState(newState);
                    deleteModal.close();
                    NotificationManager.info('Overhead successfully deleted!');

                }).catch(err => {
                console.log(err)
            })
        } catch (ex) {
            console.log('err:', ex)
        }
    }

    const [deletedContract, setDeletedContract] = useState('Contrct one');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div>
            <h3>Overheads</h3>
            <div style={{textAlign: ' right', paddingBottom: '20px'}}><Button variant="contained" size={"large"}
                                                                              onClick={modal.open} color={"primary"}>Add
                Overhead</Button>
            </div>
            <TableContainer component={Paper} className="Overhead">
                <Table aria-label="simple table" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nr.crt</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Amount RON</TableCell>
                            <TableCell align="left"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rowsState.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rowsState
                        ).map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{(page * rowsPerPage) + index + 1}</TableCell>
                                <TableCell component="th" scope="row">
                                    <select
                                        onChange={(ev) => editRow(row.id, ev.target.value, overheadsType.CATEGORY)}>
                                        {Category.map(item => {
                                            return row.category && <option selected={row.category === item.value}
                                                                           key={Math.floor(Math.random() * 200000)}>{item.value}</option>
                                        })}
                                    </select>
                                </TableCell>
                                <TableCell align="left">
                                    <input
                                        onChange={(ev) => editRow(row.id, ev.target.value, overheadsType.NAME)}
                                        type={'text'}
                                        value={row.name}/> </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, overheadsType.AMMOUNT)}
                                    type={'numeric'} value={row.amount}/> </TableCell>
                                <TableCell onClick={() => deleteContract(row.id)} align="left"><span
                                    className="Delete-Symbol">&#10060;</span></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalOverheads}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                rowsPerPageOptions={[10, 15, 20, 25]}
                ActionsComponent={TablePaginationActions}
            />
            <Modal
                modal={modal}
                title={'Create Contract'}
                form={true}
                actions={
                    <>
                        <Button variant="contained" size={"large"} onClick={formik.handleSubmit} color="primary">
                            Create
                        </Button>
                        <Button variant="contained" size={"large"} color="secondary" onClick={() => {
                            modal.close();
                            formik.resetForm()
                        }}>
                            Cancel
                        </Button>
                    </>}
            >
                <div>
                    <Select options={Category} name={'category'} formik={formik} required/>
                    <Input formik={formik} name={'name'} label={'Name'} required/>
                    <Input formik={formik} name={'amount'} label={'Amount'} required/>
                    {/*<Input formik={formik} name={'amount_ron'} label={'Amount RON'} required/>*/}
                </div>
            </Modal>
            <Modal
                className={"Delete"}
                modal={deleteModal}
                title={'Delete contract'}
                form={true}
                actions={
                    <>
                        <Button variant="contained" size={"large"} onClick={handleDeleteContract}
                                color="primary">
                            Delete
                        </Button>
                        <Button variant="contained" size={"large"} color="secondary" onClick={() => {
                            deleteModal.close();
                            deleteFormik.resetForm();
                        }}>
                            Cancel
                        </Button>
                    </>}
            >
                <div className="Delete">Are you sure you want to delete this overhead?</div>
            </Modal>
        </div>
    );
}

export default Overhead;
