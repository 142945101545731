import React, {useState, useEffect, useContext} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useModal} from "../hooks/useModal";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {PricingDataContext} from "../store/PricingDataProvider";
import {formatNumber} from "../components/helpers";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        maxWidth: 800
    },
});


const contractsType = {
    FTE: 'fte',
    REVENUE: 'revenue',
    EMPLOYEES: 'employees',
    PRODUCT_COST: 'productCosts',
    OTHER_COST: 'otherCosts',
    GROSS_MARGIN: 'grossMargin',
    GROSS_MARGIN_PERCENTAGE: 'grossMarginPercentage',
}


const Metrics = () => {
    //total
    const context = useContext(PricingDataContext);
    const {
        fte,
        setFte,
        revenue,
        setRevenue,
        employees,
        setEmployees,
        productCost,
        setProductCost,
        otherCost,
        totalGrossMargin,
        setOtherCosts,
        setTotalGrossMargin,
        totalGrossMarginPercentage,
        setTotalGrossMarginPercentage,
        ammount,
        setAmmount,
        cash,
        setCash,
        fteNC,
        setFteNC,
        costPerFteNC,
        setCostPerFteNC,
        salaryNC,
        setSalaryNC,
        productCostNC,
        setProductCostNC,
        otherCostNC,
        setOtherCostNC,
        totalEstimatedCostNC,
        setTotalEstimatedCostNC,
        targetedMarginNC,
        setTargetedMarginNC,
        revenueNC,
        setRevenueNC,
        grossMarginNC,
        setGrossMarginNC,
        additionalOverheadNC,
        setAdditionalOverheadNC
    } = context;

    const modal = useModal();
    const classes = useStyles();


    return (
        <div>
            <TableContainer>
                <h3>Overview key metrics</h3>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Current situation</TableCell>
                            <TableCell align="left">Including new contract</TableCell>
                            <TableCell align="left">Contribution new contract</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Revenue</TableCell>
                            <TableCell align="left">{formatNumber(revenue,0)}</TableCell>
                            <TableCell align="left">{formatNumber(revenue + revenueNC,0)}</TableCell>
                            <TableCell
                                align="left">{formatNumber((revenueNC / revenue + revenueNC) / 1000,0)}%</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Gross margin</TableCell>
                            <TableCell align="left">{formatNumber(totalGrossMargin,0)}</TableCell>
                            <TableCell align="left">{formatNumber(totalGrossMargin + grossMarginNC,0)}</TableCell>
                            <TableCell
                                align="left">{formatNumber((grossMarginNC / (totalGrossMargin + grossMarginNC)) * 100,0)}%</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Overhead</TableCell>
                            <TableCell align="left">{formatNumber(ammount,0)}</TableCell>
                            <TableCell align="left">{formatNumber(additionalOverheadNC + ammount,0)}</TableCell>
                            <TableCell
                                align="left">{formatNumber(((additionalOverheadNC) / (additionalOverheadNC + ammount)) * 100,0)}%</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Operating profit</TableCell>
                            <TableCell align="left">{formatNumber(totalGrossMargin - ammount,0)}</TableCell>
                            <TableCell
                                align="left">{formatNumber((totalGrossMargin + grossMarginNC) - (additionalOverheadNC + ammount),2)}</TableCell>
                            <TableCell
                                align="left">{formatNumber(((grossMarginNC - additionalOverheadNC) / ((totalGrossMargin + grossMarginNC) - (additionalOverheadNC + ammount))) * 100,0)}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Operating profit%</TableCell>
                            <TableCell
                                align="left">{formatNumber(((totalGrossMargin - ammount) / revenue) * 100,2)}%</TableCell>
                            <TableCell
                                align="left">{formatNumber(((totalGrossMargin + grossMarginNC) - (additionalOverheadNC + ammount)) / (revenue + revenueNC) * 100,2)}%</TableCell>
                            <TableCell align="left">{formatNumber((((totalGrossMargin + grossMarginNC) - (additionalOverheadNC + ammount)) / (revenue + revenueNC) * 100) - (((totalGrossMargin - ammount) / revenue) * 100),0)}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Cleaning FTE</TableCell>
                            <TableCell align="left">{formatNumber(fte,2)}</TableCell>
                            <TableCell
                                align="left">{formatNumber(fte + fteNC,2)} </TableCell>
                            <TableCell align="left">{formatNumber((fteNC / (fte + fteNC)) * 100,2)}%</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Overhead per Cleaning FTE</TableCell>
                            <TableCell align="left">{formatNumber(ammount / fte,2)}</TableCell>
                            <TableCell
                                align="left">{formatNumber((additionalOverheadNC + ammount) / (fte + fteNC),2)}</TableCell>
                            <TableCell
                                align="left">{formatNumber(((((additionalOverheadNC + ammount) / (fte + fteNC)) / (ammount / fte)) - 1) * 100,2)}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}

export default Metrics;
