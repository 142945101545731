import React, {useState, useEffect, useContext, useCallback, useMemo, useRef} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useModal} from "../hooks/useModal";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import * as Yup from 'yup';
import './ContractSummary.css';
import {PricingDataContext} from "../store/PricingDataProvider";
import axios from "axios";
import {TablePaginationActions} from '../components/Pagination';
import {Paper, TablePagination} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import NumberFormat from "react-number-format";
import {parse} from "dotenv";
import {formatNumber} from "../components/helpers";

require('dotenv').config();

const useStyles = makeStyles({
    table: {
        maxWidth: 250
    },
    lastCols: {
        width: 150
    }
});

// function createData(id, client, fte, revenue, employees, productCosts, otherCosts) {
//     return {id, client, fte, revenue, employees, productCosts, otherCosts};
// }

const contractsType = {
    FTE: 'fte',
    REVENUE: 'revenue',
    EMPLOYEES: 'employees_cost',
    PRODUCT_COST: 'product_cost',
    OTHER_COST: 'other_cost',
    GROSS_MARGIN: 'gross_margin',
    GROSS_MARGIN_PERCENTAGE: 'gross_margin_percentage',
}

const ContractsSummary = () => {
    //total
    const [rowsState, setRowState] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    //pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalContracts, setTotalContracts] = useState(0)
    const [order, setOrder] = useState(true);

    const [orderBy, setOrderBy] = useState('calories');

    let history = useHistory();


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/api/contracts`).then(res => {
            setRowState(res.data);
            setTotalContracts(res.data.length)
            refState.current = res.data
        }).catch(err => {
            console.log('ERR GET')
        })
    }, [])
    const sortClient = () => {
        order ? rowsState.sort((a, b) => a.client.localeCompare(b.client)) :
            rowsState.sort((a, b) => b.client.localeCompare(a.client))
        setOrder(prevState => !prevState)
    }
    // console.log(value);
    useEffect(() => {
        setFte(totalType(contractsType.FTE));
        setRevenue(totalType(contractsType.REVENUE));
        setEmployees(totalType(contractsType.EMPLOYEES));
        setProductCost(totalType(contractsType.PRODUCT_COST));
        setOtherCosts(totalType(contractsType.OTHER_COST));
        setTotalGrossMargin(totalType(contractsType.GROSS_MARGIN))
        //check formula
        setTotalGrossMarginPercentage((totalType(contractsType.GROSS_MARGIN) + totalType(contractsType.REVENUE)) * 100)
    }, [rowsState, setRowState]);

    const refState = useRef(null);

    useEffect(() => {
        let timer = null
        if (updateStatus && refState.current?.length === rowsState.length) {
            timer = setTimeout(() => {
                console.log('UPDATE REQUEST');
                try {
                    axios.put(`${process.env.REACT_APP_URL}/api/contracts`, rowsState).then(res => {
                        NotificationManager.success('Contracts table successfully updated!');
                    }).catch(err => {
                        console.log(err)
                    })
                } catch (ex) {
                    console.log("CONTRACTS:", ex)
                }
            }, 3000)
        }
        return () => {
            clearTimeout(timer)
            setUpdateStatus(true)
        }
    }, [rowsState])

    const context = useContext(PricingDataContext);

    const {
        fte,
        setFte,
        revenue,
        setRevenue,
        employees,
        setEmployees,
        productCost,
        setProductCost,
        otherCost,
        setOtherCosts,
        totalGrossMargin,
        setTotalGrossMargin,
        totalGrossMarginPercentage,
        setTotalGrossMarginPercentage,
        isAuth,
        setIsAuth
    } = context;
    useEffect(() => {
        if (!isAuth) {
            history.push('/login')
        }
    }, [isAuth])
    const [enableReinitialize, setEnableReinitialize] = useState(true)

    const createModal = useModal();
    const deleteModal = useModal();

    const classes = useStyles();

    const editRow = useCallback((id, value, type) => {
        refState.current = rowsState;
        let rowCopy = [...rowsState];
        let elementFind = rowCopy.filter(el => el.id === id);
        elementFind[0][type] = type === 'client' ? value : parseFloat(value);
        console.log(rowCopy)
        let grossMargin = elementFind[0]['gross_margin'] = elementFind[0]['revenue'] - (elementFind[0]['fte'] * elementFind[0]['employees_cost']) - elementFind[0]['product_cost'] - elementFind[0]['other_cost'];
        elementFind[0]['gross_margin_percentage'] = ((grossMargin / elementFind[0]['revenue']) * 100).toFixed(2);
        setRowState(rowCopy);
    }, [rowsState]);

    const totalType = (type) => {
        let total = rowsState.map((item) => {
            return item[type]
        }).reduce((total, current) => parseFloat(total) + parseFloat(current), 0);
        return total

    };
    const createFormik = useFormik({
        initialValues: {
            client: '',
            fte: '',
            revenue: '',
            employees_cost: '',
            product_cost: '',
            other_cost: '',
            gross_margin: '',
            gross_margin_percentage: '' // revenue + product
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object({
            client: Yup.string().required('Client field is required.'),
            fte: Yup.number().required('Fte field is required.'),
            revenue: Yup.number().required('Revenue field is required.'),
            employees_cost: Yup.number().required('Employee field is required.'),
            product_cost: Yup.number().required('Product cost field is required.'),
            other_cost: Yup.number().required('Other cost field is required.')
        }),
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            try {
                axios.post(`${process.env.REACT_APP_URL}/api/contracts`, values)
                    .then(res => {
                        setPage(0)
                        axios.get(`${process.env.REACT_APP_URL}/api/contracts`).then(res => {
                            setRowState(res.data);
                            setTotalContracts(res.data.length)
                            refState.current = res.data
                        }).catch(err => {
                            console.log('ERR GET')
                        })
                        NotificationManager.success('Contract successfully created!');
                        createFormik.resetForm();
                        createFormik.close();
                    }).catch(err => {
                    console.log(err)
                })
                createModal.close();
                createFormik.resetForm();
                setEnableReinitialize(false);
            } catch (error) {
                console.log('Error:', error);
                setEnableReinitialize(false);
                alert(error)
            } finally {
                setSubmitting(false)
            }
        }
    });
    const grossMargin = useMemo(() => {
        if (createFormik.values.fte && createFormik.values.revenue && createFormik.values.employees_cost && createFormik.values.product_cost && createFormik.values.other_cost) {
            return parseInt(createFormik.values.revenue) - (parseFloat(createFormik.values.fte) * parseFloat(createFormik.values.employees_cost)) - parseInt(createFormik.values.product_cost) - parseInt(createFormik.values.other_cost)
        }
    }, [createFormik.values.fte, createFormik.values.revenue, createFormik.values.employees_cost, createFormik.values.product_cost, createFormik.values.other_cost])

    const grossMarginPercentage = useMemo(() => {
        return ((grossMargin / parseInt(createFormik.values.revenue)) * 100).toFixed(2)
    }, [createFormik.values.gross_margin])

    useEffect(() => {
        createFormik.setFieldValue('gross_margin', grossMargin)
        createFormik.setFieldValue('gross_margin_percentage', grossMarginPercentage)
    }, [createFormik.values]);

    const deleteContract = (id) => {
        deleteModal.open(id);
        let contractExist = rowsState.find(it => it.id === id);
        if (contractExist) {
            setDeletedContract(contractExist.client)
        }
    }
    const [deletedContract, setDeletedContract] = useState('');

    async function handleDeleteContract() {
        try {
            axios.delete(`${process.env.REACT_APP_URL}/api/contracts/${deleteModal.data}`)
                .then(res => {
                    let state = [...rowsState];
                    let newState = state.filter(item => item.id !== deleteModal.data)
                    setRowState(newState);
                    deleteModal.close();
                    NotificationManager.info('Contract successfully deleted!');

                }).catch(err => {
                console.log(err)
            })
        } catch (ex) {
            console.log('err:', ex)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    return (
        <div>
            <h3>Contract Summary</h3>
            <div style={{textAlign: 'right', paddingBottom: '20px'}}><Button variant="contained" size={"large"}
                                                                             onClick={createModal.open}
                                                                             color={"primary"}>Add
                Contract</Button>
            </div>
            <TableContainer
                component={Paper} className={'Contract-Summary'}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nr.crt</TableCell>
                            <TableCell style={{cursor:'pointer'}} onClick={() => sortClient()} align="left">Client</TableCell>
                            <TableCell align="left">FTE</TableCell>
                            <TableCell align="left">Revenue</TableCell>
                            <TableCell align="left">Employees </TableCell>
                            <TableCell align="left">Product </TableCell>
                            <TableCell align="left">Other </TableCell>
                            <TableCell align="left">Gross margin</TableCell>
                            <TableCell className={classes.lastCols} align="left">Gross m &#37;</TableCell>
                            <TableCell className={classes.lastCols} align="left"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rowsState.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rowsState
                        ).map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{(page * rowsPerPage) + index + 1}</TableCell>
                                <TableCell component="th"
                                           scope="row">
                                    {row.client}
                                </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, contractsType.FTE)}
                                    type={'number'}
                                    value={row.fte}/> </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, contractsType.REVENUE)}
                                    type={'numeric'} value={row.revenue}/> </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, contractsType.EMPLOYEES)}
                                    type={'numeric'} value={row.employees_cost}/> </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, contractsType.PRODUCT_COST)}
                                    type={'float'} value={row.product_cost}/> </TableCell>
                                <TableCell align="left"><input
                                    onChange={(ev) => editRow(row.id, ev.target.value, contractsType.OTHER_COST)}
                                    type={'float'}   value={row.other_cost}/> </TableCell>
                                <TableCell
                                    align="left">{Math.round(row.gross_margin).toLocaleString()} </TableCell>

                                <TableCell align="left">{Math.round(row.gross_margin_percentage).toLocaleString()}</TableCell>
                                <TableCell onClick={() => deleteContract(row.id)} align="left"> <span
                                    className="Delete-Symbol">&#10060;</span>
                                </TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalContracts}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                }}
                rowsPerPageOptions={[10, 15, 20, 25]}
                ActionsComponent={TablePaginationActions}
            />
            <Modal
                modal={createModal}
                title={'Create Contract'}
                form={true}
                actions={
                    <>
                        <Button variant="contained" size={"large"} onClick={createFormik.handleSubmit} color="primary">
                            Create
                        </Button>
                        <Button variant="contained" size={"large"} color="secondary" onClick={() => {
                            createModal.close();
                            createFormik.resetForm();
                        }}>
                            Cancel
                        </Button>
                    </>}
            >
                <div>
                    <Input formik={createFormik} name={'client'} label={'Client'} required/>
                    <Input formik={createFormik} name={'fte'} label={'FTE'} required/>
                    <Input formik={createFormik} name={'revenue'} label={'Revenue excluding VAT'} required/>
                    <Input formik={createFormik} name={'employees_cost'}
                           label={'Average employee salary including social taxes'} required/>
                    <Input formik={createFormik} name={'product_cost'} label={'Product cost'} required/>
                    <Input formik={createFormik} name={'other_cost'} label={'Other cost'} required/>
                    <Input formik={createFormik} name={'gross_margin'} label={'Gross margin'} readOnly required/>
                    <Input formik={createFormik} name={'gross_margin_percentage'} label={'Gross margin %'} readOnly
                           required/>
                </div>
            </Modal>
            <Modal
                className={"Delete"}
                modal={deleteModal}
                title={'Delete contract'}
                form={true}
                actions={
                    <>
                        <Button variant="contained" size={"large"} onClick={handleDeleteContract}
                                color="primary">
                            Delete
                        </Button>
                        <Button variant="contained" size={"large"} color="secondary" onClick={() => {
                            deleteModal.close();
                        }}>
                            Cancel
                        </Button>
                    </>}
            >
                <div className="Delete">Are you sure you want to delete {deletedContract}?</div>
            </Modal>
        </div>
    );
}

export default ContractsSummary;
