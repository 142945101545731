import React, {useState, useEffect, useContext} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useModal} from "../hooks/useModal";
import './NewContract.css';
import {PricingDataContext} from "../store/PricingDataProvider";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        maxWidth: 800
    },
});


const contractsType = {
    FTE: 'fteNC',
    REVENUE: 'revenueNC',
    COST_PER_FTE: 'costPerFteNC',
    SALARY: 'salaryNC',
    PRODUCT_COST: 'productCostNC',
    OTHER_COST: 'otherCostNC',
    TARGETED_MARGIN: 'targetedMarginNC',
    GROSS_MARGIN: 'grossMarginNC',
    ADDITIONAL_OVERHEAD: 'additionalOverheadNC',
};


const Metrics = () => {

    const modal = useModal();
    const classes = useStyles();
    const context = useContext(PricingDataContext);
    const {
        fteNC,
        setFteNC,
        costPerFteNC,
        setCostPerFteNC,
        salaryNC,
        setSalaryNC,
        productCostNC,
        setProductCostNC,
        otherCostNC,
        setOtherCostNC,
        totalEstimatedCostNC,
        setTotalEstimatedCostNC,
        targetedMarginNC,
        setTargetedMarginNC,
        revenueNC,
        setRevenueNC,
        grossMarginNC,
        setGrossMarginNC,
        additionalOverheadNC,
        setAdditionalOverheadNC
    } = context;


    const editRow = (value, type) => {
        switch (type) {
            case  contractsType.FTE :
                setFteNC(parseFloat(value));
                break;
            case  contractsType.COST_PER_FTE :
                setCostPerFteNC(parseFloat(value));
                break;

            case  contractsType.PRODUCT_COST :
                setProductCostNC(parseFloat(value));
                break;
            case  contractsType.OTHER_COST :
                setOtherCostNC(parseFloat(value));
                break;
            case  contractsType.TARGETED_MARGIN :
                setTargetedMarginNC(parseFloat(value));
                break;

            case  contractsType.ADDITIONAL_OVERHEAD :
                setAdditionalOverheadNC(parseFloat(value));
                break;
        }

    };
    useEffect(() => {
        setSalaryNC(fteNC * costPerFteNC);
    }, [fteNC, costPerFteNC])

    useEffect(() => {
        setTotalEstimatedCostNC((fteNC * costPerFteNC) + productCostNC + otherCostNC)
    }, [productCostNC, salaryNC, otherCostNC])

    useEffect(() => {
        setRevenueNC(((fteNC * costPerFteNC) + productCostNC + otherCostNC) * (targetedMarginNC / 100) + ((fteNC * costPerFteNC) + productCostNC + otherCostNC))
    }, [totalEstimatedCostNC,targetedMarginNC])

    useEffect(() => {
        setGrossMarginNC(((fteNC * costPerFteNC) + productCostNC + otherCostNC) * (targetedMarginNC / 100))
    }, [revenueNC, targetedMarginNC, salaryNC, totalEstimatedCostNC])
    return (
        <div>
            <TableContainer className="NewContract">
                <h3>Pricing tool for new contract</h3>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">FTE </TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.FTE)}
                                type={'number'}
                                value={fteNC}/> </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Cost per FTE </TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.COST_PER_FTE)}
                                type={'number'}
                                value={costPerFteNC}/> </TableCell>
                        </TableRow>

                        <TableRow className={"Total-Price"}>
                            <TableCell align="left">Salary costs </TableCell>
                            <TableCell align="left"><strong>{salaryNC.toLocaleString()}</strong> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Products </TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.PRODUCT_COST)}
                                type={'number'}
                                value={productCostNC}/> </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Other direct costs </TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.OTHER_COST)}
                                type={'number'}
                                value={otherCostNC}/> </TableCell>
                        </TableRow>
                        <TableRow className={"Total-Price"}>
                            <TableCell align="left">Total estimated cost </TableCell>
                            <TableCell align="left"><strong>{totalEstimatedCostNC.toLocaleString()}</strong>
                            </TableCell>

                        </TableRow>

                        <TableRow>
                            <TableCell align="left">Targeted margin % </TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.TARGETED_MARGIN)}
                                type={'number'}
                                value={targetedMarginNC}/>%</TableCell>
                        </TableRow>

                        <TableRow className={"Total-Price"}>
                            <TableCell align="left">Revenue target </TableCell>
                            <TableCell
                                align="left"><strong>{revenueNC.toLocaleString()}</strong></TableCell>
                        </TableRow>
                        <TableRow className={"Total-Price"}>
                            <TableCell align="left">Gross margin</TableCell>
                            <TableCell
                                align="left"><strong>{grossMarginNC.toLocaleString()}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Additional overhead cost</TableCell>
                            <TableCell align="left"><input
                                onChange={(ev) => editRow(ev.target.value, contractsType.ADDITIONAL_OVERHEAD)}
                                type={'number'}
                                value={additionalOverheadNC.toLocaleString()}/> </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}

export default Metrics;
