import {useState} from 'react';

export const useModal = () => {
    const [{isOpen, data}, setModal] = useState({isOpen: false, data: null});
    const open = (data) => {
        setModal({isOpen: true, data: data && data})
    };
    const close = () => {
        setModal({isOpen: false, data: null})
    };
    return {
        data,
        isOpen,
        open,
        close
    }
};
