import React from 'react';
import './Select.css';

const Select = ({name, options, required, formik}) => {
    return (
        <div className="Select">
            <select onChange={formik.handleChange(name)}>
                {options.map((item) => {
                    return <option  onClick={() => {
                        formik.setFieldValue(name, item.value)
                    }} key={item.key}>{item.value}
                    </option>
                })}
            </select>
            {required && formik.touched[name] && formik.errors[name] ? (
                <div className="Error">{formik.errors[name]}</div>
            ) : null}
        </div>
    )
}

export default Select;